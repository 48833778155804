import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Error',
    component: () => import('../views/Error.vue') 
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue') 
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/Reservas',
        name: 'Reservas',
        component: () => import(/* webpackChunkName: "Reservas" */ '../views/operaciones/Reservas')
      },
      {
        path: '/Invoice',
        name: 'Invoice',
        component: () => import(/* webpackChunkName: "Invoice" */ '../views/operaciones/Invoice')
      },
      {
        path: '/HInvoice',
        name: 'Historic Invoice',
        component: () => import(/* webpackChunkName: "Invoice" */ '../views/operaciones/HistoricInvoice')
      },
      {
        path: '/Products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "Products" */ '../views/operaciones/Products')
      },
      {
        path: '/Locations',
        name: 'Locations',
        component: () => import(/* webpackChunkName: "Locations" */ '../views/operaciones/Location')
      },
      {
        path: '/ProductParams',
        name: 'Parametros de Productos',
        component: () => import(/* webpackChunkName: "ProductParams" */ '../views/operaciones/configuration/ProductParams')
      },
      {
        path: '/CustomerParams',
        name: 'Parametros de clientes',
        component: () => import(/* webpackChunkName: "ProductParams" */ '../views/operaciones/configuration/CustomerParams')
      },
      {
        path: '/ReservasParams',
        name: 'Parametros Reservas',
        component: () => import(/* webpackChunkName: "ProductParams" */ '../views/operaciones/configuration/ReservasParams')
      },
      {
        path: '/Vehicles',
        name: 'Vehiculos',
        component: () => import(/* webpackChunkName: "Vehicles" */ '../views/asignaciones/Vehicles')
      },
      {
        path: '/Customers',
        name: 'Parametros de Clientes',
        component: () => import(/* webpackChunkName: "Customers" */ '../views/operaciones/Customers')
      },
      {
        path: '/Suppliers',
        name: 'Proveedores',
        component: () => import(/* webpackChunkName: "Suppliers" */ '../views/operaciones/configuration/Suppliers')
      },
      {
        path: '/Adicionales',
        name: 'Adicionales',
        component: () => import(/* webpackChunkName: "Adicionales" */ '../views/operaciones/configuration/Adicionales')
      },
      {
        path: '/Receipt',
        name: 'Pagos',
        component: () => import(/* webpackChunkName: "Receipt" */ '../views/operaciones/Receipt')
      },
      {
        path: '/Assignment',
        name: 'Asignaciones',
        component: () => import(/* webpackChunkName: "Assignment" */ '../views/asignaciones/Assignment')
      },
      {
        path: '/Commissions',
        name: 'Comisiones',
        component: () => import(/* webpackChunkName: "Commissions" */ '../views/asignaciones/Commissions')
      },
      {
        path: '/ExchangeRate',
        name: 'Tipos de cambio',
        component: () => import(/* webpackChunkName: "ExchangeRate" */ '../views/operaciones/configuration/ExchangeRate')
      },
      {
        path: '/Permissions',
        name: 'Permisos',
        component: () => import(/* webpackChunkName: "Permissions" */ '../views/configuration/Permissions')
      },
      {
        path: '/Users',
        name: 'Usuarios',
        component: () => import(/* webpackChunkName: "Users" */ '../views/configuration/Users')
      }

      // {
      //   path: '/Companias',
      //   name: 'Companias',
      //   component: () => import(/* webpackChunkName: "Companias" */ '../views/contabilidad/Companias')
      // },
      // {
      //   path: '/AsientosDetalles',
      //   name: 'AsientosDetalles',
      //   component: () => import(/* webpackChunkName: "AsientosDetalles" */ '../views/contabilidad/AsientosDetalles')
      // },
      // {
      //   path: '/AsientosRecurrentes',
      //   name: 'AsientosRecurrentes',
      //   component: () => import(/* webpackChunkName: "AsientosRecurrentes" */ '../views/contabilidad/AsientosRecurrentes')
      // },
    ] 
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  const res = await Store.dispatch('verifyToken')
  
  if (to.name !== 'Login') {
   
    if (res.state) {
      next()
    } else {
      router.push('/')
    }
  } else {
    console.log(to.name)
    if (res.state) {
      router.push('Home')
    } else {
      next()
    }
  }
})

export default router
