import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// import IdleVue from 'idle-vue'

import {
  Message,
  MessageBox,
  Notification
} from 'element-ui'
import VueTelInputVuetify from "vue-tel-input-vuetify";

Vue.use(VueTelInputVuetify)
Vue.config.productionTip = false
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification

Vue.prototype.$convertToMoney = function (x) {
  x = Number(x)      
  return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

Vue.prototype.$dayLabels = function(year, month) {
  let monthIndex = month - 1 // 0..11 instead of 1..12
  let names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ]
  let date = new Date(year, monthIndex, 1)
  let result = []
  while (date.getMonth() == monthIndex) {
    // result.push(date.getDate() + '-' + names[date.getDay()]);
    result.push(date.getDate())
    date.setDate(date.getDate() + 1)
  }
  return result
}

Vue.prototype.$capitalize = function (x) {
  if (x){
    return x.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
  } 
  else {
    return ''
  } 
}

Vue.prototype.$convertDateCalendarGoogle = function(fechaParametro, horaParametro, horas, operacion){
  //metodo para convertir a la fecha que se necesita en el calendario de google
  let dateGoogle = ''
  let minutos = ''
  let hora = ''
  let month = ''
  let day = ''
  let star_date_time = new Date(`${fechaParametro}T${horaParametro}-06:00`)
  
  if (operacion == '-'){
    star_date_time.setHours(star_date_time.getHours() - horas)
  } else {
    star_date_time.setHours(star_date_time.getHours() + horas)
  }
  star_date_time = new Date(star_date_time)
  
  let year = `${star_date_time.getFullYear()}`
  
  if ((star_date_time.getMonth()+1) > 9){
    month = `${star_date_time.getMonth()+1}`
  } else {
    month = `0${star_date_time.getMonth()+1}`
  }
  
  if ((star_date_time.getDate()) > 9){
    day = `${star_date_time.getDate()}`
  } else {
    day = `0${star_date_time.getDate()}`
  }

  if ((star_date_time.getHours()) > 9){
    hora = `${star_date_time.getHours()}`
  } else {
    hora = `0${star_date_time.getHours()}`
  }

  if ((star_date_time.getMinutes()) > 9){
    minutos = `${star_date_time.getMinutes()}`
  } else {
    minutos = `0${star_date_time.getMinutes()}`
  }

  dateGoogle = `${year}-${month}-${day}T${hora}:${minutos}:00-06:00`
  return dateGoogle
}

// const eventsHub = new Vue()
 
// Vue.use(IdleVue, {
//   eventEmitter: eventsHub,
//   idleTime: 600000
// })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
