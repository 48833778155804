<template>
  <v-app>
    <v-main class="main-content">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  onIdle() {
    console.log('hacer logout')
    this.$store.dispatch("logoutUser")
    .then(() => {
      this.$router.push({ name: "Login" })
    })
    .catch((err) => {
      alert(err)
    })
  },
};
</script>

<style>
.main-content{
  background-color: #f8f9fa;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 11px !important;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 24px !important;
  padding: 0 10px !important;
}
.texto-auto-cuentas{
  font-size: 12px !important;
  
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    min-height: 0 !important; 
}
/* .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
  min-height: 23px !important;
} */
</style>
