import Vue from 'vue'
import Vuex from 'vuex'
import VueJwtDecode from "vue-jwt-decode"
import {
  axiosB,
  axiosN506
} from "../api/axiosBd"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: localStorage.getItem("token") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
    accessTokenPy: localStorage.getItem("tokenPy") || null,
    refreshTokenPy: localStorage.getItem("refreshTokenPy") || null,
    toggleSidebar: true,
    tokenU: localStorage.getItem('tokenU') || null,
    kvid: localStorage.getItem('kvid') || null,
    name: localStorage.getItem('name') || null,
    first_name: localStorage.getItem('first_name') || null,
    last_name: localStorage.getItem('last_name') || null,
  },
  mutations: {
    setAccessTokenPy(state, token) {
      state.accessTokenPy = token
      localStorage.setItem('tokenPy', token)
    },
    setRefreshTokenPy(state, token) {
      state.refreshTokenPy = token
      localStorage.setItem('refreshTokenPy', token)
    },
    setDestroyTokens(state) {
      state.accessToken = null
      state.refreshToken = null
      state.toggleSidebar = false
      state.kvid = null
      state.name = null
      state.first_name = null
      state.last_name = null
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("token")
      localStorage.removeItem("kvid")
      localStorage.removeItem("name")
      localStorage.removeItem("first_name")
      localStorage.removeItem("last_name")

      state.accessTokenPy = null
      state.refreshTokenPy = null
      localStorage.removeItem("refreshTokenPy")
      localStorage.removeItem("tokenPy")
    },

    setAccessToken(state, token) {
      state.accessToken = token

      localStorage.setItem('token', token)
      // console.log(VueJwtDecode.decode(token))
      state.kvid = VueJwtDecode.decode(token).id
      state.name = VueJwtDecode.decode(token).name
      state.first_name = VueJwtDecode.decode(token).first_name
      state.last_name = VueJwtDecode.decode(token).last_name

      localStorage.setItem('kvid', state.kvid)
      localStorage.setItem('name', state.name)
      localStorage.setItem('first_name', state.first_name)
      localStorage.setItem('last_name', state.last_name)
    },
    setRefreshToken(state, token) {
        state.refreshToken = token
        localStorage.setItem('refreshToken', token)
    },
    
    mutActiveSidebar(state, data) {
      state.toggleSidebar = data
    },
    mutBoards(state, data){
      state[data.board] = data.data
    },
    mutAsientoDetalles(state, data) {
      state.asientoDetalles = data
    }
  },
  actions: {
    accAsientoDetalles({commit}, data){
      commit('mutAsientoDetalles', data)
    },
    actActiveSidebar({commit}, data) {
      console.log(data)
      if (data){
        commit('mutActiveSidebar', data)
      } else {
        commit('mutActiveSidebar', false)
      }
    },
  
    logoutUser({ commit,}) {
      commit("setDestroyTokens")
      localStorage.clear()
    },
    async verifyToken({ state,commit }) {
      let res = {
        state: true,
        message: "",
      }
  
      if (state.accessToken) {
        // console.log(VueJwtDecode.decode(state.accessToken).exp)
        if (VueJwtDecode.decode(state.accessToken).exp*1000 < new Date().getTime()) {
          
          try {
            const response = await axiosN506.post("auth/refresh/", {
              "refreshToken": state.refreshToken
            })
            commit("setAccessToken", response.data.token)
          } catch (err) {
            console.log(err)
            if (err.response.status == 401) {
              res.state = false
              res.message = `Verify token: Unauthorized user, please login.`
            } else {
              res.state = false
              res.message = `Verify token: ${ err.response ? err.response.data.message : 'No server response'}`
            }
          }
        }
      } else {
        res.state = false
        res.message = `Verify token: Can't get access token.`
      }
      return res
    },

    async verifyTokenPy({ state,commit }) {
      let res = {
        state: true,
        message: "",
      }
  
      if (state.accessTokenPy) {
        // console.log(VueJwtDecode.decode(state.accessTokenPy).exp)
        if (VueJwtDecode.decode(state.accessTokenPy).exp*1000 < new Date().getTime()) {
          console.log('esta vencido')
          try {
            const response = await axiosB.post("api/token/refresh/", {
              "refresh": state.refreshTokenPy
            })
            commit("setAccessTokenPy", response.data.access)
          } catch (err) {
            console.log(err)
            if (err.response.status == 401) {
              res.state = false
              res.message = `Verify tokenPy: Unauthorized user, please login.`
              console.log(res.message)
            } else {
              res.state = false
              res.message = `Verify tokenPy: ${ err.response ? err.response.data.message : 'No server response'}`
            }
          }
        }
      } else {
        res.state = false
        res.message = `Verify token: Can't get access token.`
        alert(res.message)
      }
      return res
    },


    // async getData({ state,commit, dispatch }, obj){
    //   await dispatch('verifyToken')
      
    //   axiosN506({
    //     method: 'GET',
    //     url: `/users/list`,
    //     headers: { Authorization: `Bearer ${state.accessToken}` },
    //   })
    //   .then((result) => {
    //     commit('mutBoards',{board: obj.board, data:result.data.data})
    //   }).catch((err) => {
    //     console.log(err)
    //   });
    // }
  },
  modules: {
  }
})
