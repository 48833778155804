import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa' || 'md' || 'mdi'
  },
  theme: {
      themes: {
          light: {
              primary: '4A6286',
              'primary-red': '#c91f1a',
              'primary-second': '#1c1c1b',
              primarytext: '#7f8080',
          },
      },
  },
});
