import axios from 'axios'

const axiosB = axios.create({
  baseURL: 'https://connect.webdgroup.com/',
  // timeout: 50000,
  headers: {
    'Accept-Language': 'es',
  }
})

const axiosN506 = axios.create({
  baseURL: 'https://506transfers.webdgroup.com/'
  // baseURL: 'http://localhost:8700'
  ,
})

export {
  axiosB,
  axiosN506
}